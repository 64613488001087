import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import l3i from "../images/univs/l3i.png"

import uqam from "../images/univs/uqam.jpg"

import concordia from "../images/univs/concordia.png"

import sabanci from "../images/univs/sabanci.png"

const members = [
  {
    image: l3i,
    name: "La Rochelle University - L3i",
    description:
      "La Rochelle University is a young and dynamic French university funded in 1993. The university develops a multi-disciplinary higher education and research strategy aiming at tackling the societal challenges linked to its signature research agenda, namely Smart and Sustainable Urban Littorals , where the design of future networking and computing paradigms takes a major role. Funded at the creation of the university, the Laboratory of Informatics Image Interaction (L3i), is the university’s Digital Sciences Research Center gathering all the University’s research activities related to Computer Science: Knowledge Engineering; Software Engineering; Human Machine Interactions; Image Processing; Document Analysis and Information Retrieval; as well as Future Networks, IoT and Mobile Systems. L3i hosts about 100 researchers (among which 35 faculty members) working in the above-mentioned domains. The eAdapt team, one of the three L3i teams and the one involved in this project, aims at creating self-adaptive systems, that can be either networking systems or software systems. The Lab has also a wide expertise on industry-academia partnerships and collaboration allowing securing average annual funds of about 1.9M€.",
  },
  {
    image: uqam,
    name: "UQAM university - Computer Science",
    description:
      "Université du Québec à Montréal (UQAM) is a French-language public university with an international reputation. The originality and specific characteristics of its programs, its cutting-edge research often focused on social concerns as well as its innovations in creation have contributed to its reputation. The university offers training on the Montreal campus and at itsregional campuses in the Greater Montreal Area. Created in 1969 by the Quebec government with an accessibility mandate, UQAM is an important player in the scientific, social and cultural progress of Quebec society. The professors of UQAM computer science department are involved in several research topics ranging from mathematical computing to software econometrics, theoretical and combinatorial computing, artificial intelligence, software engineering, telecommunication networks, computer system, microelectronics or bioinformatics. Research in the department is therefore developing mainly in various laboratories, chairs and research groups, each of which focuses on the problems of one or more major areas of computing cited above.",
  },
  {
    image: concordia,
    name: "Concordia University - CIISE",
    description:
      "The Concordia Institute for Information Systems Engineering (CIISE) was established in 2002 with a vision of building a unique approach to Systems Engineering based on multidisciplinary research. Concordia University is located in one of Canada’s major urban centres for information technology, aerospace industry, pharmaceutical industry, and governmental departments. It capitalizes on the exceptional potential of Montréal to attract a large, linguistically diverse and mobile trainee population. Since its inception, the Institute has been a runaway success conducting numerous research projects and attracting major government and industry research partners, external research funding, and top-ranked students from around the world. Interdisciplinary research is a logical step toward success in the information age and researchers at CIISE specialize in the applications of information systems to engineering, offering a unique multidisciplinary environment for education and research in association with both the private sectors and the Government of Canada.",
  },
  {
    image: sabanci,
    name: "Sabanci University - Faculty of Engineering and Natural",
    description:
      "Sabanci University was founded in 1998 and has quickly become one of the leading research universities in Turkey. In 2018, Sabanci University was awarded as the Most Innovative University by Ministry of Science and Industry Turkey. Sabanci University also leads on several categories its peers in Turkey in QS and THE rankings. Sabanci University has a state-of-the-art campus in Tuzla Istanbul with several centers of excellence facilities including Center of Data Analytics.",
  },
]

const Member = ({ image, name, description, professors }) => {
  return (
    <div className="border-l-4 border-scoringLightBlue pl-4 my-4">
      <img
        width="300px"
        src={image}
        alt={name}
        className=" w-52 object-contain"
      />
      <h2 className="text-scoringLightBlue">{name}</h2>
      <p>{description}</p>
    </div>
  )
}
function ConsortiumPage({ location }) {
  return (
    <Layout pagePath={location.pathname}>
      <div className="pt-10">
        <h1 className=" text-scoringBlue">Consortium</h1>
        <ul className="flex flex-col md:flex-row flex-wrap gap-1">
          {members.map(member => (
            <Member {...member}></Member>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default ConsortiumPage
